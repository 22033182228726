import React ,{ useState, useEffect } from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import SEO from "../components/seo"
import "../assets/index.css"
import Carousel from 'react-bootstrap/Carousel'
import {Router , Route} from "@reach/router"
import firebase from "../components/firebase"
import { InlineWidget } from 'react-calendly';

const useItems = () => {
  const [animation, setItems] = useState([]); //useState() hook, sets initial state to an empty array

  useEffect(() => {
  const unsubscribe = firebase
  .firestore()
  .collection("animations")
  .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
             id: doc.id,
          ...doc.data(),
        }));
        setItems(listItems)
      })
      return () => unsubscribe();
    },[]);
  return animation;
};

const EventItems = () => {
  const [events, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
  const unsubscribe = firebase
  .firestore()
  .collection("events")
  .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
             id: doc.id,
          ...doc.data(),
        }));
        setItems(listItems)
      })
      return () => unsubscribe();
    },[]);
  return events;
};

const GetHomeImageUrls = () => {
  
    const [homeImages, setHomeMenuImagesUrl] = useState([]);
    
    useEffect(() => {
  
      const unsubscribe = firebase
  
      .firestore()
      .collection("homeImages")
      .onSnapshot(snapshot => {
            const listItems = snapshot.docs.map(doc => ({
                id: doc.id,
              ...doc.data(),
            }));
            setHomeMenuImagesUrl(listItems)
          });
          return () => unsubscribe();
        },[]);
  
      return homeImages;
};  

const Home = () => {

  const animations = useItems();
  const events = EventItems();
  const imageUrls = GetHomeImageUrls();


  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // Create a new date assuming the dateStr is in local time zone
    const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
    return date.toLocaleDateString('en-US', options);
  };
  
  return (
    <div className="home_container">
      <SEO title="Dark Horse Catering" />
      <div className="home_image_slider">

        <div className="animated-intro">
          {animations.map((animation, index) => (
              <p key={animation.id} className={`text-animation-slider slide-${index}`}>{animation.animation}</p>
          ))}
        </div>

        <Carousel indicators={false} interval={3000} touch={true} wrap={true}>
        
          <Carousel.Item className="index_carousel_images"> 
            <Image  filename="grilled-wings.jpeg" />
          </Carousel.Item>

          <Carousel.Item className="index_carousel_images"> 
            <Image  filename="pizza-oven.jpeg" />
          </Carousel.Item>

          <Carousel.Item className="index_carousel_images"> 
            <Image  filename="shrimp-sandwhich.jpeg" />
          </Carousel.Item>

          <Carousel.Item className="index_carousel_images"> 
            <Image  filename="catering_serving.jpg" />
          </Carousel.Item>
        </Carousel>

      </div>
      <div className="location_of_buisness">
        <div className="container_location_buisenss">
          <h2>We Cater In The Chicagoland Area</h2>
          <span>And More Locations</span>
        </div>
      </div>
      <div className="index_event_card_container">

        {events.length > 0 ? (
          <h2 style={{fontFamily: 'serif', margin: '55px'}} >Our Upcoming Events</h2>
        ):(
          <div>
            <h2 >Follow us on Instagram for future events!</h2>
            <a href="https://www.instagram.com/therealdarkhorse/">
              <img  src="https://user-images.githubusercontent.com/26069518/97885300-cbae7600-1cec-11eb-926d-f387546b5baf.png"/>
            </a>
          </div>
        )}

        <div className="events-container">
            {events.map((event, index) => (
                <div className="event-card" key={index}>
                    <div className="card-header">
                        <h2 className="event-name">{event.eventName}</h2>
                    </div>
                    <div className="card-body">
                        <p className="event-info"><strong>When: </strong>{formatDate(event.date)}</p>
                        <p className="event-info"><strong>Where:</strong> {event.where}</p>
                        {event.city &&
                          <p className="event-info"><strong>City:</strong> {event.city}</p>
                        }
                        <p className="event-info"><strong>Details:</strong> {event.additionalComment}</p>
                    </div>
                </div>
            ))}
        </div>

      </div>

      <div className="specials">
        <h2>Event Specials Menu</h2>
        {imageUrls.map(url =>(
          <div 
            style={{display: 'inline-block'}}
            key={url.id}>

            { url.image_url === "" && <span></span> }
            { url.image_url !== "" && 
              <img alt="Specials" src={url.image_url} />
            }
          </div>
        ))}
      </div>

      <InlineWidget url="https://calendly.com/pineapple-tech-solutions/book-your-catering-event?hide_gdpr_banner=1" styles={{ minWidth: '320px', height: '700px' }} />

        <div className="cookingcourse-container">
        <h2>
          Terrell's Tomahawk Ribeye
        </h2>
        <h2 className="youTube">
          On YouTube
        </h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/rQldLEig9Ts?si=mmxRO0JP6XKJMyQn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <div className="cooking-course-link-container">
          <h2>
            Joing Chef Terrell on a tasty cooking course.
          </h2>
          <Link to="/coursepayment">Read More</Link>
        </div>
        </div>

      <div className="see-our-recipes">
        <h3>We love to cook and we hope you do too.</h3>
        <p>Our team has amazing recipes you can try at home.</p>
        <Link to="/blog">Click Here</Link>        

        <Image 
          style={{
            maxWidth: '60 !important',
            marginTop: '30px !important'
          }}
        filename="chef.png"/>
      </div>

      <div className="chef-experience">
        <h3>Book Chef Terrell</h3>
        <p>Ever wanted your own personal chef?</p>
        <p>Well now you can, book chef Terrell for an Airbnb like experience.</p>
        <a href="mailto:darkhorsechef12@gmail.com">darkhorsechef12@gmail.com</a>
        <Image filename="cheff-experience.jpeg"/>
      </div>

      <div className="social-media">
        <h1>Follow Us Online</h1>
        <ul>
          <li>
            <a href="https://www.instagram.com/therealdarkhorse/">
              <Image  filename="instagram.png"/>
            </a>
            <p>Stay up to date with our events and check out our delicious portfolio.</p>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCHARp8DUNpL9P7znieMy8Pg/videos">    
              <Image  filename="youtube.png" />  
            </a>
            <p>Check out our channel, we have video's of our operation and get to meet our team.</p>
          </li>
          <li>
            <a href="https://www.facebook.com/DarkHorsePastries">
              <Image  filename="facebook.png"/>
            </a>
            <p>See what we like and talk with the team on Facebook.</p>              
          </li>

          <li>
            <a href="https://www.snapchat.com/add/blackvelvet1976">
              <Image  filename="snapchat.png"/>
            </a>
            <p>Watch our live feed to find out where we'll be and what we're serving.</p>
          </li>
        </ul>
      </div>  
    </div>
  );
  
};

export default Home;